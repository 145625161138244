import React from 'react';
import bebekbasic from './assets/img/basic-duck.png';
import instagramLogo from './assets/img/instagram-icon.png';
import jonhsonLogo from './assets/img/jonhson-logo.png';
import './App.css';

const App = () =>{
	return(
		<div className="container">
			<div>
				<div className="banner"></div>
				<div className="duck-basic">
					<div className="duck-image">
						<img src={bebekbasic} alt="Basic Duck" />
					</div>
				</div>
				<h3 className="heading">DUCKLOGY</h3>
				<div className="card">
					<h3 className="heading">Quacks like a duck, Release the Quackin!</h3>
					<p>Ducklogy is a limited NFT collection of rubber duck toy - unique digital collectibles living on the Ethereum blockchain.</p>
					<p className="italic">Discover the best ducks in this collection on OpenSea</p>
					<h4 className="tag">@Ducklogy</h4>
				</div>
				<div className="instagram-wrapper">
					{/* <span className="instagram-icon">
						
					</span> */}
					<img className="instagram-icon" src={instagramLogo} alt="Instagram" />
					<span>
						<a className="instagram-link" href="https://www.instagram.com/ducklogy/"  target="_blank" without rel="noreferrer">
							instagram.com/ducklogy
						</a>
					</span>
				</div>
			</div>
			<div className="copyright-wrapper">
				<img src={jonhsonLogo} alt="Instagram" />
				<p>Copyright &#169; 2021 Ducklogy</p>
			</div>
		</div>
	)
}

export default App;
